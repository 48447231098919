@import "../../common/styles/index";

html, body {
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 5px;
  @media (max-width: 540px) {
    padding: 0;
    background-color: white !important;
    display: block;
  }
  .brand {
    display: block;
    margin: 20px auto;
    width: 250px;
    position: relative;  
    width: 280px;
    left: 0;
    top: 0;
    transition: .5s;
    transition-timing-function: ease-in-out;
    &.loggedIn {
      width: 125px;
      left: -160px;
      top: -30px;
      @media (max-width: 540px) {
        left: -130px;
      }
      @media (max-width: 375px) {
        left: -105px;
      }
      @media (max-width: 325px) {
        left: -80px;
      }
    }
  }
}

#page {
  background-color: white;
  position: relative;
  padding: 25px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  border-radius: 6px;
  min-width: 475px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    .button {
      width: 100%;
    }
  }
  @media (max-width: 540px) {
    width: 100%;
    min-width: 0;
    box-shadow: none;
  }
}

.formWrapper {
  &.login {
    margin-bottom: 25px;
    .loading {
      margin-bottom: -15px;
    }
  }
  &.changePassword {
    &.isLoading form {
      opacity: .2;
      pointer-events: none;
    }
    .loading {
      position: absolute;
      margin-top: 92px;        
    }
    .loadingWrapper {
      p {
        position: absolute;
        left: 0;
        right: 0;
        margin-top: 150px;    
      }
    }
  }
}
form {
  display: flex;
  flex-direction: column;
  &.disabled {
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }
  .formGroup {
    margin: 10px 0;
    label, input {
      font-family: $fontFamily;
    }
  }
  button[type="submit"] {
    margin: 20px 0;
    width: 100%;
  }
}

footer {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 480px) {
    padding: 20px 0px 0px;
  }
  a {
    margin: 0 10px;
  }
}

.buttonLink {
  font-size: 14px;
  -webkit-appearance: listitem;
  background: none;
  border: none;
  font-family: $fontFamily;
  cursor: pointer;
  &.logout {
    background-image: url('./images/logout.svg');
    position: absolute;
    right: 15px;
    top: 22px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: .8;
    transition: opacity .3s;
    &:hover {
      opacity: 1;
    }
  }
}

.inlineError {
  background-image: url('./images/exclamation-mark.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 15px;
  padding-left: 20px;
  color: #E6655B;
  font-style: italic;
  font-size: 12px;
  animation: slideInDown .3s;
}

.success {
  max-width: 500px;
  margin: 0 auto 30px auto;
  text-align: center;
  line-height: 24px;
}
