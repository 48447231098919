@import url('https://fonts.googleapis.com/css?family=Cairo:400,700&display=swap');
@import "./animations.scss";

$fontFamily: 'Cairo', sans-serif;

body {
  margin: 0;
  padding: 0;
  font-family: $fontFamily;
}

#root {
  .button {
    border-radius: 50px;
    text-transform: none;
    box-shadow: none;
    font-weight: bold;
    line-height: 18px;
    padding: 2px;
    height: 50px;
    min-width: 40px;
    font-family: $fontFamily;
    &:disabled {
      opacity: .5;
    }
    span {
      font-size: 18px;
    }
    @media (max-width: 767px) {
      width: 150px;
      margin: 0 auto;
      font-size: 18px;
    }
  }  
}

.loading {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 67px;
  height: 67px;
  transform: translate(-33.5px, -33.5px) scale(0.335) translate(33.5px, 33.5px);
  .circle {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    &.secondary {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }
  }
}

.snackbar {
  z-index: 5 !important;
  bottom: 0px !important;  
  > div {
    padding: 10px 15px;
  }
  &.therapist {
    bottom: 75px !important;
  }
  @media (min-width: 540px) {
    margin: 0 25px;
  }
  @media (min-width: 768px) {
    &.therapist {
      bottom: 110px !important;
    }
  }
  [role="alertdialog"] {
    font-family: $fontFamily;
    margin-bottom: 10px;
    border-radius: 5px;
    button span {
      font-weight: bold;
    }
    @media (max-width: 768px) {
      font-size: 12px;
      padding: 6px 10px;
      margin-right: 10px;
      margin-left: 10px;
    }
    color: #333;
    background-color: #fff;
  }
}

.loadingWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 12px;
  }
}
